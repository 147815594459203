<template>
  <div class="header">
    <h2 class="logo">
      <img src="@/assets/logo.png" style="width: auto;height: auto" />
    </h2>
  </div>
</template>

<script>
    export default {
        name: "HeaderPublic",
      components: {

      },
      mounted() {
      },
      computed: {

      },
      data() {

      },
      methods: {
      },

    }
</script>

<style scoped>
.header {
  background-color: #0b4e85;
  margin-top: -20px;
}
.logo {
  padding: 20px 0;
}
</style>
